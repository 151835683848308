<template>
  <v-app>
    <v-app-bar 
      app 
      color="purple darken-3" 
      dark
    >
      <v-app-bar-title>Audio Portfolio</v-app-bar-title>
      <v-spacer />
      <v-btn v-if="userLoaded" @click="saveTracks" text>Save</v-btn>
      <v-btn
        v-if="!userLoaded"
        @click="signIn"
      >Sign in</v-btn>
      <v-btn
        v-if="userLoaded"
        @click="signOut"
      >Sign out</v-btn>
    </v-app-bar>

    <v-main app>
      <v-container v-if="userLoaded">
        <v-card>
          <v-container>
            <v-row>
                <v-img
                class="rounded-circle mx-auto"
                max-height="150"
                max-width="150"
                :src="user.photoURL"
                />
            </v-row>
            <v-row>
              <v-card-title class="mx-auto">
                {{user.displayName}}
              </v-card-title>
            </v-row>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <v-card>
          <v-data-table
            :headers="tracks_header"
            :items="tracks"
            :hide-default-footer="true"
            :hide-default-header="false"
            :disable-pagination="true"
            class="elevation-1"
          >
            <template v-slot:item.link="{ item }">
              <v-btn icon :href="item.link">
              <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <v-text-field
          label="Add Track"
          v-model="newTrack"
          outlined
          @keydown.enter="addTask()"/>
        </v-card>
      </v-container>
    </v-main>

    <v-footer app>&copy; 2022</v-footer>

  </v-app>
</template>

<script>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth"
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


export default {
  name: 'App',

  data: () => ({
    user : null,
    userLoaded : null,
    newTrack : "",
    tracks_header : [
      { text: "Link", value: "link"},
      { text: "Title", value: "title"},
      { text: "Description", value: "description"},
    ],
    tracks : [
      // Demo tracks.
      { title: "🅱️",
        description: "A bold new branding choice. Let us redesign your logo.",
        link: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        },

      { title: "10 hours of incomprehensible screaming",
        description: "There might be a secret track in there. Why don't you take a look? :)",
        link: "https://www.youtube.com/watch?v=AMm04ScFqDE",
        },
    ],
  }),
  created() {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDV5Yw2bx94Z6LflNsbF-k9HX_t9NZ99Lg",
      authDomain: "audio-portfolio-9653.firebaseapp.com",
      projectId: "audio-portfolio-9653",
      storageBucket: "audio-portfolio-9653.appspot.com",
      messagingSenderId: "1055016166798",
      appId: "1:1055016166798:web:cca974aa8f9a74acfdf0df",
      measurementId: "G-0LC1ZYDNQ1"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.user = user;

      if (user) {
        this.userLoaded = true;
        this.getTracks();
        this.username = user.displayName;
      } else {
        this.userLoaded = false;
        this.username = null;
      }
      
    });
  },

  methods : {
    addTask() {
      console.log("Started")
      if(this.tracks.some(track => track.title == this.newTrack)) {
        this.newTrack = ""
        return
      }
      this.tracks.push({title: this.newTrack, description: ""})
      this.newTrack = ""
      return

    },
    signIn() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .catch((error) => {
          console.error(error.message)
        });
    },
    signOut() {
      const auth = getAuth();
      signOut(auth)
    },
    async getTracks() {
      const db = getFirestore();
      const user = this.user
      if (user == null) return;
      const docSnapshot = await getDoc(doc(db, "users", user.uid))
      if (docSnapshot.exists()) {
        this.tracks = docSnapshot.data().tracks;
      } else {
        console.warn("No tracks found for user.")
      }
    },
    async saveTracks() {
      const db = getFirestore();
      const user = this.user
      if (user == null) return;
      await setDoc(doc(db, "users", user.uid), {tracks: this.tracks})
    },
  },
};
</script>
